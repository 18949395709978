import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const DataGrid = ({
  columns,
  data,
  title,
  options,
  expandDetails = null,
  conditionalRowStyles = undefined,
  fixedHeight = null,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const rowCount = data.length;
  const rowHeight = 70;
  const headerHeight = 50;
  const maxVisibleRows = 20;
  const calculatedHeight =
    Math.min(rowCount, maxVisibleRows) * rowHeight + headerHeight;

  const customStyles = {
    headCells: {
      style: {
        fontSize: "9px",
        fontWeight: "700",
        backgroundColor: options?.headerBgColor || "white",
        color: options?.headerColor || "black",
        textAlign: "center",
        lineHeight: "2",
        minHeight: "auto",
        justifyContent: "center",
        padding: "2px",
      },
    },
    cells: {
      style: {
        fontSize: "9px",
        padding: "5px",
        justifyContent: "center",
        textAlign: "center",
        lineHeight: "1",
        minHeight: "auto",
        borderBottom: "1px solid #ddd",
      },
    },
  };

  //Colors for columns
  const modifiedColumns = columns.map((column) => {
    // if (column.name === "STATUM GPA") {
    //   return { ...column, style: { backgroundColor: "#5894f0" } };
    // }
    // else if (column.name === 'EFFICIENY RATIO') {
    //   return { ...column, style: { backgroundColor: '#5894f0' } };
    // } else if (column.name === 'ASSET SIZE ($ M)') {
    //   return { ...column, style: { backgroundColor: '#f6f9fe' } };
    // }
    // else 
    if (column.name === "STATUM RANK") {
      return { ...column, style: { backgroundColor: "#5894f0" }, sortFunction: column.sortFunction || undefined, };
    } else {
      return { ...column, width: "auto", sortFunction: column.sortFunction || undefined,
       };
    }
  });

  const exportToExcel = (type) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: type,
      type: "array",
    });

    const date = new Date();
    const formattedFileName = `${title} -Export-${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${formattedFileName}.${type}`);

    setIsDropdownOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="custom-table-header">
        {options?.showHeader ? (
          <>
            <label className="mb-0 ms-2">{options?.headerTitle || ""}</label>
            {options?.export ? (
              <div className="custom-dropdown" ref={dropdownRef}>
                <button
                  className="dropdown-toggle"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Export
                </button>
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <div onClick={() => exportToExcel("csv")}>
                      <i class="fa-solid fa-file-csv pr-2"></i>CSV
                    </div>
                    <div onClick={() => exportToExcel("xlsx")}>
                      <i class="fa-solid fa-file-excel pr-2"></i>Excel
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <DataTable
        columns={modifiedColumns}
        data={data}
        responsive
        direction="auto"
        // striped
        fixedHeader
        fixedHeaderScrollHeight={`${calculatedHeight}px`}
        highlightOnHover
        sortIcon={<FontAwesomeIcon icon={faArrowDown} />}
        dense
        pagination={rowCount > 1}
        persistTableHead
        paginationPerPage={30}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        expandableRows={expandDetails !== null}
        expandableRowsComponent={expandDetails}
      />
    </div>
  );
};

export default DataGrid;
