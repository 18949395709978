import React, { useEffect, useState } from "react";

const SummaryChart = ({
  item,
  data,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [processedHtml, setProcessedHtml] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState("");

  useEffect(() => {
    if (data && data.length > 0) {
      setNoData("");
      setIsLoading(false);
    } else {
      setNoData("No data available");
    }
  }, [data]);

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    }
  }, [data]);

  useEffect(() => {
    if (item?.html && data.length > 0) {
      let updatedHtml = item.html;

      // Replace placeholders with corresponding values from data
      Object.keys(data[0]).forEach((key) => {
        const regex = new RegExp(`\\[${key}\\]`, "g");
        updatedHtml = updatedHtml.replace(regex, data[0][key]);
      });

      setProcessedHtml(updatedHtml);
    }
  }, [item, data]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");

    if (x.style.display === "none") {
      el.forEach((ele) => (ele.style.display = "none"));
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {isLoading ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="summary-chart-chart-title">{item?.title} </div>
          ) : (
            <div></div>
          )}
          {processedHtml !== "" ? (
            <div
              className="type-summary-chart-container"
              dangerouslySetInnerHTML={{ __html: processedHtml }}
            ></div>
          ) : (
            <div className="mt-4"></div>
          )}
        </>
      )}

      {currPage === "manage_layout" && (
        <div
          className="text-right more-options-wrapper"
          style={{ fontSize: "15px", marginTop: "-30px" }}
        >
          <button
            type="button"
            onClick={handleShowMoreOptions}
            title="More"
            className="btn-more-options"
          >
            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
          </button>
          <div className="more-options" style={{ display: "none" }}>
            <button
              type="button"
              onClick={() => {
                setOpenEditChartModal(true);
                setChartSlug(item.cht);
                setDashChartSlug(item.dash_chrt);
              }}
            >
              <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
              <span>Edit Chart</span>
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteChartModal(true);
                setChartSlug(item.cht);
                setDashChartSlug(item.dash_chrt);
              }}
            >
              <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryChart;
