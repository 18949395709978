import React, { PureComponent, useState, useEffect } from "react";
import Chart from "../genericChart/Chart";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AdjustableChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
  defaultType,
  handleSubmit,
  filterData,
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [formData, setFormData] = useState({});

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      setData(apiData?.data);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <div className="chart-title">{noDataFound}</div>
      ) : (
        <>{item?.title !== "" ? <div> </div> : <div></div>}</>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          {/* <div className='row'>
            <div className='col-md-6'>
                <div className='mt-3 ms-16'> */}
          {/* Chart Wrapper component */}
          <Chart
            defaultType={defaultType}
            name={item?.title}
            data={data}
            options={{
              filters: {
                show: true,
                menus: ["bar", "line", "area", "table", "export", "image"],
              },
              style: { border: true, borderRadius: true, bgColor: "white" },
              header: {
                name: item?.title,
                textAlign: "center",
                bgColor: "#f5f5f5",
              },
              // footer: { name: item?.y_axis_label, fontSize: 15, textAlign: "center", bgColor: "#eee" },
              height: 300,
              lineDot: true,
              gridLine: false,
              xAxis: {
                tick: item?.show_xaxis_tick === "1" ? true : false,
                tickLine: item?.show_xaxis_tickline === "1" ? true : false,
                axisLine: item?.show_xaxis_axisline === "1" ? true : false,
                columns: item?.x_cord,
                fontSize: 10,
                angle: 0,
                label: { 
                  value: item?.x_axis_label,
                  position: "bottom"
              }
              },
              yAxis: {
                tick: item?.show_yaxis_tick === "1" ? true : false,
                tickLine: item?.show_yaxis_tickline === "1" ? true : false,
                axisLine: item?.show_yaxis_axisline === "1" ? true : false,
                columns: [item?.y_cord],
                label: {
                  value: item?.y_axis_label, 
                  angle: -90, 
                  position: "insideLeft",
              }
              },
              borderRadius: "15px",
              stackBar: true,
              colors: [item?.color, "#E76E50"],
              legend: false,
              label: {
                show: item?.show_label === "1" ? true : false,
                fontSize: 10,
                color: "grey",
              },
              barSize: null,
              gradient: false,
              radius: [5, 5, 5, 5],
              operator: item?.operator,
              filterData: filterData,
              handleSubmit: handleSubmit,
            }}
          />
          {/* </div>
            </div>
           </div> */}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{ fontSize: "15px" }}
            >
              <button
                type="button"
                className="mr-2"
                onClick={() => {
                  setOpenEditChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                }}
              >
                <i className="fa-solid fa-pencil" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="mr-4"
                onClick={() => {
                  setOpenDeleteChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                }}
              >
                <i className="fa-solid fa-trash-alt"></i>{" "}
              </button>
            </div>
          )}
        </>
      )}{" "}
    </>
  );
};

export default AdjustableChart;
