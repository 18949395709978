import React from 'react'
import { useEffect,useState, useContext, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import EditUser from '../forms/EditUser';
import axios from 'axios';
import UserService from './../../services/UserService';
import CreateUser from '../forms/CreateUser';
import { Button } from 'react-bootstrap';
import "../css/userman.css";
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("./../../config/server.js");

function Table() {
    
      const [selectedRows, setSelectedRows] = useState([]);
      const {tab , setTab}=useContext(Context);
      const [appDataGridRender, setAppDataGridRender] = useState();
      const [tableData, setTableData] = useState();
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(null);
      const {selectedOrganization} = useContext(Context);
      const [displayNone, setDisplayNone] = useState('none');
      const [loading, setLoading] = useState(false);

      /* token - start */
  const [token, setToken] = useState(null);
  const [organizationList, setOrganizationList] = useState([]);
  const [userPrivileged, setUserPrivileged] = useState(false);
  const [rowHovered, setRowHovered] = useState('');
  const [editRowClicked, setEditRowClicked] = useState('');
  const [modalClosed, setModalClosed] = useState(false);
  const [actions, setActions] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [memberProfile, setMemberProfile] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [userInvitePopup, setUserInvitePopup] = useState(false);
  const [userPasswordReset, setUserPasswordReset] = useState(false);
  const [userData, setUserData] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const filteredData = tableData?.filter((row) => 
    row.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
    row.email_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dropdownRef = useRef(null);

  const toggleTooltip = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipIndex(index);
    setTooltipStates(newTooltipStates);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const userProfileGet = () => {
    axios.post(serverConfig.api_base_url + "member_profile_get", {
      token: token,
      org: selectedOrganization?.slug
    }).then(response=> {
      console.log(response.data, 'user_profile_data');
      setMemberProfile(response.data.item);
    }).catch(err=>{
      console.log(err);
    })
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const RoleBadge = ({option, index, toggleTooltip, showTooltip, row }) => {
    return (
    <div key={index} className="flex-column flex items-center py-2 roles" onMouseEnter={() => {toggleTooltip(index);setRowHovered(row);}} onMouseLeave={()=>{setRowHovered('');}}>
    <img src={`https://ui-avatars.com/api/?name=${option}
    &length=1
    &${option==='Owner'?'background=9D6EF8':option==='Manager'?
    'background=F6B637':'background=6ADA00'}&format=svg&color=fff`} alt='image'
     height={36} width={36} className='role-avatar rounded-xl' />
      {row===rowHovered && tooltipIndex === index && (
        <div key={index} className="role-tooltip">
          {option}
        </div>
      )}
     </div>
    )
  }

  function OptionsDropdownCard({data, dropdownRef}){
  
   return(
  
    <div  ref={dropdownRef} id='orgHover' className={`flex gap-2 align-middle p-2 oragnisationHover`} style={{alignItems:'center'}} onClick={()=>{}}>
  <div>
  </div>
  
  <div className='options-heading line-clamp-1'>{data}</div>
  </div>
  
    )
   }

  const handleActive = (emailid, changeActive, slug) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: token, user:slug, org:selectedOrganization?.slug, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock, slug) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: token, user:slug, org:selectedOrganization?.slug, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
  
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
      setDisplayNone('hello');
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('more-options') && !event.target.classList.contains('more-options-wrapper') && !event.target.classList.contains('btn-more-options') && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains('fa-solid')) {
        setShowDropdown('');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleReInviteUser = (e) => {

    e.preventDefault();
    setLoading(true);
    axios.post(serverConfig.api_base_url + "user_re_invite", {
      token : token, 
      org: selectedOrganization?.slug,
      email: userData?.email_id,
      name: userData?.name,
      user: userData?.slug,
      url: window.location.host
    }).then(response=> {
      console.log(response?.data);

      if(response.data.status === 200)
      {
        setUserInvitePopup(false);
        setLoading(false);
      handleShowAlertModal('Invite sent');
      setTimeout(() => {
        handleCloseAlertModal();
      }, 6000);
      }
      else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
          setLoading(false);
        }, 6000);
      }

    }).catch(err=> {
      console.log(err);
      setLoading(false);
    })
  }

  const handlePasswordReset = (e) => {

    e.preventDefault();
    setLoading(true);
    axios.post(serverConfig.api_base_url + "user_reset_password", {
      token : token, 
      org: selectedOrganization?.slug,
      user: userData?.slug,
    }).then(response=> {
      console.log(response?.data);

      if(response.data.status === 200)
      {
        setUserPasswordReset(false);
        setLoading(false);
      handleShowAlertModal('Please check your email.');
      setTimeout(() => {
        handleCloseAlertModal();
      }, 6000);
      }
      else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
          setLoading(false);
        }, 6000);
      }

    }).catch(err=> {
      console.log(err);
      setLoading(false);
    })
  }


  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));

    
      if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
        console.log('cToken', token);
        /* user_get_list */
        axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug, show_all:1})
        .then(response=>{
          console.log("user_get_list response:", response);
          if(response.data.status === 200){
            let items = response.data.items;
            if (items !== null || items !== undefined){
              let appData = [];
              items.reverse().map((item, index) => {
                let curRole = [];
                curRole = item.roles;
              });
              console.log(items, 'user_get_list');
              console.log(appData);
              setTableData(items);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));

        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_list_get",{token: token, level:'Organization'})
        .then(response=>{
          console.log('role_get_list response', response);
          console.log('role_get_list response items',response);
          if(response.data.status === 200){
           
            console.log(selectedOrganization?.slug, 'selectedOrg')
            console.log(response.data.items, 'role_options');
            setRolesList(response.data.items);
            setTooltipStates(new Array(response.data.items.length).fill(false));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
      }

      userProfileGet();
    
  }, [token, modalClosed, actions, selectedOrganization]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, !event.target.classList.contains('btn-more-options'), 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains("action-option")) {
        setDisplayNone('none');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [displayNone]);
       
       const sortDown=<i class="fa-solid fa-arrow-down-wide-short"></i>;
       const sortUp=<i class="fa-solid fa-arrow-up-short-wide"></i>;
      
       
        const dropdownItems = ['Updated', 'Option 2', 'Option 3']
        const sortingIcons= [sortDown,sortUp]
        const columns = [
        
                {
                    name: <div className='row-label ml-14 pl-1'>Name</div>,
                   
                    cell: (row) => <div className='flex flex-row'><div className="flex-column flex items-center py-2">
                    <img src={`https://ui-avatars.com/api/?name=${row.name}
                    &rounded=true&length=1
                    &${row.type==='Project'?'background=9D6EF8':row.type==='Departments'?
                    'background=F6B637':'background=6ADA00'}&format=svg&color=fff`} alt='image'
                     height={40} width={40} className='rounded-full'/>
                     </div>
                     <div className="flex-column mt-3 ml-8 py-2" ><div className='text-heading'>{row.name}</div></div>
                     </div>,
                 width: '20%',
                    sortable: false,
                   
                },
                // {
                //   selector:row=>row.name,
                //     name: '',
                //     cell: (row) => <div className="flex-column  py-2 ">
                //                     <div className='text-heading'>{row.name}</div>
                //                     {/* <div className='text-detail pt-1'>{row.detail}</div> */}
                //                      </div>,
                //     sortable: true,
                //     wrap:true,
                   
                // },
                {
                  name: <div className='row-label'>Email ID</div>,
                  selector:row=>row.type,
                  cell: (row) => <div className='pl-3'>
                            {row.email_id}
                          </div>,
                  sortable: true,
               
                  wrap:true
               },
               {
                name: <div className='row-label pl-3'>Active</div>,
                selector:row=>row.type,
                cell: (row) => 
                                  row.declined===1 ?
                                  <div className={'type-department'} onClick={() => handleActive(row.email_id, 1, row.slug)}>
                                  <div><i class="fa-regular fa-user"></i></div><div>Invite Declined</div>
                                  </div> :
                                  row.active===0 ?
                                  <div className={'type-department'} onClick={() => handleActive(row.email_id, 1, row.slug)}>
                                  <div><i class="fa-regular fa-user"></i></div><div>Disabled</div>
                                  </div> :
                                  <div className={'type-Client'} onClick={() => handleActive(row.email_id, 0, row.slug)}>
                                  <div><i class="fa-regular fa-user"></i></div><div>Enabled</div>
                                  </div>
                               ,
                sortable: true,
                wrap:true
             },
                {
                    name: <div className='row-label pl-3'>Locked</div>,
                    selector:row=>row.type,
                    cell: (row) => row.locked===0 ?
                                        <div className='type-Client' onClick={() => handleLocked(row.email_id, 1, row.slug)}>
                                        <div><i class="fa-regular fa-user"></i></div><div>Unlocked</div>
                                        </div> :
                                        <div className='type-department' onClick={() => handleLocked(row.email_id, 0, row.slug)}>
                                        <div><i class="fa-regular fa-user"></i></div><div>Locked</div>
                                        </div>
                                    ,
                    sortable: true,
                    wrap:true
                },
                {
                  name: '',
                  selector:row=>row.type,
                  cell: (row) => row?.accepted === 0 ?
                                  <i class="fa-solid fa-triangle-exclamation" title='User has not accepted the invite' style={{color: "#FF6969", fontSize: "20px"}}></i> :
                                  <></>
                                  ,
                  sortable: true,
                  width: '5%',
                  wrap:true
              },
                {
                  name: <div className='row-label'>Manager</div>,
                  selector:row=>row.type,
                  cell: (row) => <div className='pl-5'>
                            {row.manager} 
                          </div>,
                  sortable: true,
               
                  wrap:true
               },
               {
                name: <div className='row-label'>Last logged in</div>,
                selector:row=>row.type,
                cell: (row) => <div className='pl-5'>
                          {row?.last_login_time} 
                        </div>,
                sortable: true,
             
                wrap:true
             },
                {
                  name: <div className='row-label'>Roles</div>,
                  selector:row=>row.type,
                  cell: (row) => 

                  <div className='flex pl-7'>
                  {
                  row?.roles?.length<=2 ? row?.roles?.map((option, index) => {
                    return (
                        <RoleBadge option={option} index={index} toggleTooltip={() => toggleTooltip(index)} row={row} showTooltip={tooltipStates[index]}/>
                    )
                }):
                   <div className='hover-div flex align-middle justify-center text-center' >{

                    row?.roles?.slice(0,2).map((option, index) => {

                        return (
                            <>
                        <div key={index} className="flex-column flex items-center py-2 roles" onMouseEnter={()=>{toggleTooltip(index); setRowHovered(row);}} onMouseLeave={()=>{setRowHovered('')}} showTooltip={tooltipStates[index]} >
                        <img src={`https://ui-avatars.com/api/?name=${option}
                        &rounded=false&length=1
                        &${index===1?'background=9D6EF8':
                        'background=6C9BF6'}&format=svg&color=fff`} alt='image'
                         height={36} width={36} className='role-avatar rounded-xl' />
                    {tooltipIndex === index && row===rowHovered &&  (
                      <div className="role-tooltip">
                        {option}
                      </div>
                    )}
                    </div>
                      </>
                        )
                    })
                   }
                   <div className='rounded-xl bg-gray-200 number-span' onClick={()=>{showDropdown===row?setShowDropdown(''):setShowDropdown(row);}}>
                    <div className='py-2'>
                +{row?.roles?.length-2}
               </div>
               <div className='rolesDropdownCard '>
                  {showDropdown===row && <div className='options-dropdown py-2 my-1'>  
                    {row?.roles?.map((data) => {
                      return (
                        <OptionsDropdownCard  dropdownRef={dropdownRef} data={data} />
                      )
                    })}
                    </div> }
                    </div>
              </div> 
              </div>
                }
                </div>,
                  sortable: true,              
                  wrap:true
              },
              {                   
                    name: <div className='row-label'>Actions</div>,

                  // cell: (row) =>   
                  //   <div className="text-right more-options-wrapper">
                  //   <button type="button" onClick={(e)=>{handleShowMoreOptions(e);}} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
            
                  //    <div className="more-options" style={{display: `${displayNone}`}}>
                     
                  //   <Button variant='text' onClick={() => {editRowClicked===row?setEditRowClicked(''):setEditRowClicked(row);}}><i className="fa-solid fa-pencil " aria-hidden="true"></i><span className="action-option">Edit</span></Button>
                  //   {row===editRowClicked && <div className='overlay'><EditUser modalClosed={modalClosed} setModalClosed={setModalClosed} setEditRowClicked={setEditRowClicked} orgSlug={selectedOrganization?.slug} slug={row.slug} active={row.active} lock={row.locked} manager={row.manager} name={row.name} roles={row.roles} email={row.email_id}/></div>}
                  // </div>
                  // </div>,
                  
                     cell: (row) => <div>{(memberProfile?.email === row?.email_id) ? <div onClick={()=>{console.log((memberProfile?.email !== row?.email_id), 'member')}}> </div> : <div><div className='flex flex-row'>
                      <button className='ml-4' onClick={()=>{editRowClicked===row?setEditRowClicked(''):setEditRowClicked(row);}}><i class="fa-solid fa-pencil not-0"></i>
                      </button>
                      {row?.accepted === 0 ? <button className='ml-2' onClick={()=>{setUserInvitePopup(true); setUserData(row);}}><i class="fa fa-user-plus" title='Reinvite'></i></button> : <></> }
                      {row?.allow_reset_password === 1 ? <button className='ml-2' onClick={()=>{setUserPasswordReset(true); setUserData(row);}}><i class="fa fa-key" title='Reset Password'></i></button> : <></>}
                      </div>
                      
                     {row===editRowClicked && <div className='overlay'><EditUser modalClosed={modalClosed} setModalClosed={setModalClosed} setEditRowClicked={setEditRowClicked} orgSlug={selectedOrganization?.slug} slug={row.slug} active={row.active} lock={row.locked} manager={row.manager} name={row.name} roles={row.roles} email={row.email_id}/></div>}</div> }</div> ,
                    //ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                   
              },              
            ]           

              return (
                <div className="overflow-x-auto">
                  <div className="p-3 table-custom mt-4 ">
                    <DataTable
                    
                     title={ <div className=" table-title rounded">
                     <div className=" mx-auto flex justify-between items-center">
                       {/* Left side: Three tabs flexed together */}
                       <div className="flex space-x-4">
                        <h5 className='font-semibold'>User Management</h5>
                       </div>               
                       {/* Right side: Search input and two dropdowns */}
                       <div className="flex items-center space-x-4 text-black">
                       <div class="flex items-center gap-12 mt-4">
  <div class="relative">
    <input
      type="text"
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
    />
    <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
      <i class=" text-sm fas fa-search"></i>
    </div>
  </div>
 
</div>

{/* <div className="relative inline-block">
<div
        className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center"
      >
       
         <div className='pr-5'>
         <i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>
        
         </div>
         
       
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
      <i class="fa-solid fa-chevron-down text-sm"></i> 
      </div>
</div> */}
  

<button className='sub-grp px-3 py-2' onClick={()=>{setAddUser(true)}} >
  Invite User
   </button>
  {addUser===true ? <div className='overlay'><CreateUser setAddUser={setAddUser} modalClosed={modalClosed} setModalClosed={setModalClosed} setEditRowClicked={setEditRowClicked}/></div> : <></> }
                         
                       </div>
                     </div>
                   </div>}
                      columns={columns}
                      data={filteredData}
                      responsive={true}
                      pagination={true}
                      customStyles={{
                        rows: {
                          rows: {
                            className: 'custom-hover', // Apply custom hover styles here
                          }
                        },
                        cells: {
                          className: 'p-6',
                        },
                      }}
              
                    //subHeader={TableHeader}
                   
                    />

        {userInvitePopup && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleReInviteUser}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Re-Invite User</div>
              </div>
      
              <div className='mt-4'>
                <p>Do you want to re invite <b>{userData?.name}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div flex flex-row'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} onClick={()=> {setUserInvitePopup(false);}}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} style={loading ? {display: "flex", fontSize: "13px"} : {}}>Invite {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}</button>
            </div>
          </form>
        </div>}

        {userPasswordReset && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handlePasswordReset}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Reset Password</div>
              </div>
      
              <div className='mt-4'>
                <p>Do you want to reset passowrd for <b>{userData?.name}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div flex flex-row'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} onClick={()=> {setUserPasswordReset(false);}}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} style={loading ? {display: "flex", fontSize: "13px"} : {}}>Reset {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}</button>
            </div>
          </form>
        </div>}

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
                
                  </div>
                </div>
              );
}

export default Table;